import { useState, useEffect, forwardRef } from 'react';

import './Popup.scss';
import ButtonIcon from './ButtonIcon';
import { btnProps } from '../../utils/config';
import Player from './Player';

const Button = ({ attributes, isPremium, Popup, popupContent, isBackend = false, children }, ref) => {
	const { actionType = 'link', iconPos = 'left' } = attributes;

	return <>
		<a ref={ref} {...btnProps(attributes, isPremium, isBackend)}>
			{'right' !== iconPos && <ButtonIcon attributes={attributes} />}

			{children}

			{'right' === iconPos && <ButtonIcon attributes={attributes} />}
		</a>

		{(isPremium && 'popup' === actionType) && <RenderPopup attributes={attributes} Popup={Popup} popupContent={popupContent} btnRef={ref} />}
	</>
}
export default forwardRef(Button);

const RenderPopup = ({ attributes, Popup, popupContent, btnRef }) => {
	const { actionType = 'link', popup = {} } = attributes;
	const { caption = '' } = popup;

	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const isPopup = 'popup' === actionType;

	useEffect(() => {
		if (isPopup && btnRef.current) {
			btnRef.current.addEventListener('click', () => {
				setIsPopupOpen(true);
			});
		}
	}, [actionType]);

	return <Popup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} caption={caption}>
		<PopupChildren popup={popup} popupContent={popupContent} />
	</Popup>
}

const PopupChildren = ({ popup, popupContent }) => {
	const { type = 'image', content = '', caption = '' } = popup;

	if (!content) {
		return <p className='warning'>Sorry! No {'iframe' === type ? 'Iframe Source' : type} Found. Please Insert...</p>
	}

	switch (type) {
		case 'image':
			return <img className='image' src={content} alt={caption} />;
		case 'audio':
		case 'video':
			return <Player popup={popup} />;
		case 'content':
			return popupContent;
		case 'document':
			return <div className='document'>
				<iframe src={`//docs.google.com/viewer?embedded=true&url=${content}`} />
			</div>;
		case 'iframe':
			return <div className='iframe'>
				<iframe src={content} />
			</div>;
		default:
			return null;
	}
}