import { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';

import './style.scss';
import Style from './Components/Common/Style';
import Button from './Components/Common/Button';
import usePremium from './hooks/usePremium';
import Popup from './Components/Frontend/Popup';

document.addEventListener('DOMContentLoaded', () => {
	const buttonEls = document.querySelectorAll('.wp-block-btn-button');
	buttonEls.forEach(buttonEl => {
		const nonce = JSON.parse(buttonEl.dataset.nonce);
		const attributes = JSON.parse(buttonEl.dataset.attributes);
		const { cId, popup = {} } = attributes;
		const { content = '' } = popup;

		createRoot(buttonEl).render(<>
			<Style attributes={attributes} clientId={cId} />

			<RenderButton attributes={attributes} popupContent={<div className='content' dangerouslySetInnerHTML={{ __html: content }} />} nonce={nonce} />
		</>);

		buttonEl?.removeAttribute('data-nonce');
		buttonEl?.removeAttribute('data-attributes');
	});
});

const RenderButton = ({ attributes, popupContent, nonce }) => {
	const { text } = attributes;
	const buttonEl = useRef(null);

	const { isPremium } = usePremium(nonce);

	useEffect(() => {
		window['AOS']?.init();
	}, []);

	return <Button attributes={attributes} isPremium={isPremium} Popup={Popup} popupContent={popupContent} ref={buttonEl}>
		{text && <span className='btnText' dangerouslySetInnerHTML={{ __html: text }} />}
	</Button>
}